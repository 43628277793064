<template>
  <div class="settings pt-2 pb-5">
    <h3 class="title mt-3">Download</h3>
    <div class="pt-1 pb-3">
      <p>
        Information needs to be downloaded from the server.<br>
        Last downloaded: {{this.app.storage.get('sync.downloaded') || '-'}}.
      </p>
      <button @click="doDownload()" type="button" class="btn btn-primary px-3"><i class="fas fa-cloud-download-alt"></i> Download</button>
    </div>

    <h3 class="title mt-3">Upload</h3>
    <div class="pt-1 pb-3">
      <div>
        Normally, you can upload information when suitable. For certain cases, you can have the app upload automatically.
        <div class="form-check">
          <input v-model="uploadAutomatic" class="form-check-input" type="checkbox" value="on" id="chkAutoUpload" @click="toggleAutoUpload()">
          <label class="form-check-label" for="chkAutoUpload">
            Upload automatically
          </label>
        </div>
      </div>

      <p class="mt-3">
        <span v-if="app.countElementsToUpload() > 0">The app has information that hasn't been uploaded yet; click on the button to start the upload now.</span>
        <span v-else>All information has been uploaded.</span><br>
        Last uploaded: {{this.app.storage.get('sync.uploaded') || '-'}}.
      </p>
      <button @click="showUpload()" type="button" class="btn btn-primary px-3"><i class="fas fa-cloud-upload-alt"></i>Upload</button>
    </div>

    <h3 class="title mt-3">Database maintenance</h3>
    <div class="pt-1 pb-3">
      <p>
        Information that has been uploaded can be periodically removed from the device.
        Remove uploaded information older than:<br>
        <select v-model="cleanupAge" class="form-select mt-1" id="cleanupAge" @change="setCleanupAge">
          <option value="7">1 week</option>
          <option value="14">2 weeks</option>
          <option value="21">3 weeks</option>
          <option value="30">1 month</option>
          <option value="60">2 months</option>
          <option value="90">3 months</option>
        </select>
      </p>
      <button @click="showCleanup()" type="button" class="btn btn-primary px-3"><i class="fas fa-trash-alt"></i>Clean up</button>

      <!-- <div class="mt-3">
        Du kan også la appen rydde opp automatisk.
        <div class="form-check">
          <input v-model="cleanupAutomatic" class="form-check-input" type="checkbox" value="on" id="chkAutoCleanup" @click="toggleAutoCleanup()">
          <label class="form-check-label" for="chkAutoCleanup">
            Rydd opp automatisk
          </label>
        </div>
      </div> -->
    </div>
  </div>

  <div class="modal fade" id="downloadModal" tabindex="-1" aria-labelledby="downloadModalLabel" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="downloadModalLabel">Download</h5>
        </div>
        <div class="modal-body overflow-auto" style="max-height:350px">

          <p id="download_inform"></p>

          <div class="progress">
            <div id="download_progress" class="progress-bar progress-bar-striped bg-success" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
          </div>

        </div>
        <div class="modal-footer">
          <button v-if="download_progress==1" type="button" class="btn btn-sm" disabled>One moment...</button>
          <button v-if="download_progress==2" type="button" class="btn btn-sm btn-primary px-2" data-bs-dismiss="modal">OK</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="uploadModal" tabindex="-1" aria-labelledby="uploadModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="uploadModalLabel">Upload</h5>
        </div>
        <div class="modal-body overflow-auto" style="max-height:350px">

          <p id="upload_inform">
            One moment while the information is uploading...
          </p>

          <div class="progress">
            <div id="upload_progress" class="progress-bar progress-bar-striped bg-success" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-primary px-2" data-bs-dismiss="modal" @click="hideUpload()">OK</button>
        </div>
      </div>
    </div>
  </div>
  
  <div class="modal fade" id="cleanupModal" tabindex="-1" aria-labelledby="cleanupModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="cleanupModalLabel">Clean up</h5>
        </div>
        <div class="modal-body overflow-auto" style="max-height:350px">

          <p id="cleanup_inform">
            One moment while the application is cleaning up...
          </p>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-primary px-2" data-bs-dismiss="modal" @click="hideCleanup()">OK</button>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import { Modal } from 'bootstrap';

export default {
  data() {
    return {
      download_progress: 0,
      uploadAutomatic: this.app.storage.has('app.uploadAutomatic') ? this.app.storage.get('app.uploadAutomatic') : false,
      cleanupAutomatic: this.app.storage.has('app.cleanupAutomatic') ? this.app.storage.get('app.cleanupAutomatic') : false,
      cleanupAge: this.app.storage.has('app.cleanupAge') ? this.app.storage.get('app.cleanupAge') : '14',
    }
  },

  methods: {
    doDownload() {
      console.log('Sync.vue.doDownload()');

      new Modal(document.getElementById( 'downloadModal' ), {}).show();
      this.download_progress = 1;
      this.app
        .download('download_inform','download_progress')
        .finally(() => {
          this.download_progress = 2;
        });
    },

    showUpload() {
      // The upload routine is running continuously, and will automatically update the modal if it's available
      // But it needs to be told to show the uploading status
      console.log('Sync.vue.showUpload');

      this.app.api.checkToken()
      .then(() => {
        this.app.storage.set( 'app.uploadManual', true );
        this.app.api.upload_total = this.app.countElementsToUpload();
        this.app.api.upload_done = 0;

        // Initial display
        document.getElementById( 'upload_inform' ).innerText = 'One moment while the information is being uploaded...';
        document.getElementById( 'upload_progress' ).style.width = 0;

        // Open the modal
        new Modal(document.getElementById( 'uploadModal' ), {
          backdrop: 'static',
          keyboard: false,
          focus: false
        }).show();

        // Force-call upload - in case it wasn't running
        window.application.upload();
      }).catch((error) => {
        console.error(error);

        // Initial display
        document.getElementById( 'upload_inform' ).innerText = 'Please log off and on, and try again...';
        document.getElementById( 'upload_progress' ).style.width = 0;

        // Open the modal
        new Modal(document.getElementById( 'uploadModal' ), {
          backdrop: 'static',
          keyboard: false,
          focus: false
        }).show();


        // Force-call upload - in case it wasn't running
        window.application.upload();
      });
    },

    hideUpload() {
      // The upload routine will keep on running continuously, but it needs to be told to stop showing the status
      console.log('Sync.vue.hideUpload()');
      this.app.storage.set( 'app.uploadManual', false );

      // Close the modal
      new Modal(document.getElementById( 'uploadModal' ), {}).hide();
    },

    toggleAutoUpload() {
      // For some counter intuitive reason, we need to negate this.uploadAutomatic (checkbox clicked = false, unclicked = true)
      console.log('Sync.vue.toggleAutoUpload()');
      this.app.storage.set( 'app.uploadAutomatic', !this.uploadAutomatic );
    },

    showCleanup() {
      console.log('Sync.vue.showCleanup()');
      document.getElementById( 'cleanup_inform' ).innerText = 'One moment while the app cleans up...';

      // Open the modal
      new Modal(document.getElementById( 'cleanupModal' ), {
        backdrop: 'static',
        keyboard: false,
        focus: false
      }).show();

      this.app.cleanup(this.cleanupAge);
    },

    hideCleanup() {
      console.log('Sync.vue.hideCleanup()');
      // Close the modal
      new Modal(document.getElementById( 'cleanupModal' ), {}).hide();
    },

    setCleanupAge() {
      console.log('Sync.vue.setCleanupAge()');
      this.app.storage.set( 'app.cleanupAge', this.cleanupAge );
    },

    toggleAutoCleanup() {
      console.log('Sync.vue.toggleAutoCleanup()');
      // For some counter intuitive reason, we need to negate this.uploadAutomatic (checkbox clicked = false, unclicked = true)
      this.app.storage.set( 'app.cleanupAutomatic', !this.cleanupAutomatic );
    }
  }
}
</script>